import Vue from 'vue'
import Vuex from 'vuex'
import { getLoginInfo } from '@/services/user'
import { genMenu, genAuthAsideMenu } from '@/utils/menu'

Vue.use(Vuex)

export default new Vuex.Store({
  // 登录状态，并且持久化
  state: {
    token: window.localStorage.getItem('token'),
    user: null,
    menu: [] as any[],
    asideMenu: [] as any[]
  },
  mutations: {
    setToken (state, payload) {
      state.token = payload
      window.localStorage.setItem('token', payload)
    },
    setUser (state, payload) {
      state.user = payload
    },
    setHeadMenu (state, payload) {
      state.menu = genMenu(payload)
    },
    setAsideMenu (state, payload) {
      console.log(payload, 'aa')
      // payload为当前一级路由
      state.asideMenu = genAuthAsideMenu(state.menu, payload)
    }
  },
  actions: {
    async getUserInfo ({ commit }) {
      const { data } = await getLoginInfo()
      commit('setUser', data)
      commit('setHeadMenu', data.menu_list)
    }
  },
  modules: {
  }
})
