<template>
  <y-crud ref="yCrud" v-bind="$attrs"  v-on="$listeners" :loading="loading" :data="sourceData" :options="options" :pagination="computedPagination"
    @pagination-current-change="paginationCurrentChange">
    <template v-slot:header>
      <slot>
      </slot>
    </template>
  </y-crud>
</template>

<script>
import YCrud from './crud'
export default {
  name: 'YTablePro',
  components: {
    YCrud
  },
  provide: function () {
    return {
      yTablePro: this
    }
  },
  props: {
    params: {
      type: Object,
      default () {
        return {}
      }
    },
    request: {
      type: Function
    },
    data: {
      type: Array
    },
    defaultSize: {
      type: Number,
      default: 10
    },
    options: {
      type: Object,
      default () {
        return {
          height: '100%'
        }
      }
    }
  },
  data () {
    return {
      loading: false,
      sourceData: [],
      pagination: {
        layout: 'total, sizes, prev, pager, next, jumper',
        currentPage: 1,
        pageSize: this.defaultSize,
        total: 0
      }
    }
  },
  computed: {
    computedPagination () {
      if (this.pagination.total <= this.pagination.pageSize) {
        return null
      }
      return this.pagination
    }
  },
  mounted () {
    this.loadData()
  },
  methods: {
    async loadData () {
      if (!this.request) return
      this.loading = true
      const { list, total } = await this.request({
        ...this.params,
        page: this.pagination.currentPage,
        limit: this.pagination.pageSize
      }).finally(() => {
        this.loading = false
      })
      this.sourceData = list
      this.pagination.total = total
    },
    paginationCurrentChange (currentPage) {
      this.pagination.currentPage = currentPage
      this.loadData()
    },
    onSearch (isReset = false) {
      if (isReset) {
        this.pagination.currentPage = 1
      }
      this.loadData()
    },
    reset () {
      this.pagination.currentPage = 1
      this.loadData()
    },
    refresh () {
      this.loadData()
    },
    // 实例方法重写
    showDialog (params) {
      this.$refs.d2Crud.showDialog(params)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
