<template>
  <div
    class="d2-crud d2-crud-x"
    :class="{'d2-crud-height100':(options && options.height==='100%')}"
  >
    <div class="d2-crud-header" v-if="$slots.header">
      <slot name="header"/>
    </div>
    <!-- 表格 -->
    <div class="d2-crud-body"
         v-loading="loading"
         :element-loading-text="getAttribute(loadingOptions,'text', null)"
         :element-loading-spinner="getAttribute(loadingOptions,'spinner', null)"
         :element-loading-background="getAttribute(loadingOptions,'background', null)"
         >
         <component
        :is="getTableImpl()"
        ref="elTable"

        :data="d2CrudData"
        v-bind="options"
        @select="handleSelect"
        @sort-change="handleSortChange"
        @cell-mouse-enter="handleCellMouseEnter"
        @cell-mouse-leave="handleCellMouseLeave"
        @cell-click="handleCellClick"
        @cell-dblclick="handleCellDblclick"
        @row-click="handleRowClick"
        @row-contextmenu="handleRowContextmenu"
        @row-dblclick="handleRowDblclick"
        @header-click="handleHeaderClick"
        @header-contextmenu="handleHeaderContextmenu"
        @current-change="handleCurrentChange"
        v-on="_tableListeners"
      >
        <!-- 选择，展开，索引 -->
        <component
            :is="getTableColumnImpl()"
            v-if="(selectionRow || selectionRow === '') "
            type="selection"
            :label="handleAttribute(selectionRow.title, '')"
            v-bind="forBindProps(selectionRow)"
          />

          <component
            v-if="(expandRow || expandRow === '') "
            :is="getTableColumnImpl()"
            type="expand"
            :label="handleAttribute(expandRow.title, '')"
            v-bind="forBindProps(expandRow)"
          >
            <template  slot-scope="scope">
              <slot name="expandSlot" :scope="scope" :index="scope.$index" :row="scope.row"/>
            </template>
          </component>

          <component
            :is="getTableColumnImpl()"
            v-if="indexRow || indexRow === ''"
            type="index"
            :label="handleAttribute(indexRow.title, '')"
            v-bind="forBindProps(indexRow)"
          />

            <!-- 使用d2-column递归组件 -->
            <d2-column v-for="(item,index) in columns"
                :key="buildColumnKey(item,index)"
                :item="item"
                @cell-data-change="handleCellDataChange"
                @cell-component-ready="handleCellComponentReady"
                @cell-component-custom-event="handleCellComponentCustomEvent"
            >
            <template slot-scope="scope" :slot="item.key+'Slot'">
              <template v-if="item.rowSlot">
                <slot :name="item.key+'Slot'" :row="scope.row" :index="scope.index" :rowIndex="scope.index"/>
              </template>
            </template>
        </d2-column>
        <!-- 操作表格column -->
        <component
          :is="getTableColumnImpl()"
          v-if="rowHandle"
          v-bind="forBindPropsRowHandle(rowHandle)"
        >
          <template slot-scope="scope">

            <template
              v-for="(item, index) in _handleFlatBtns"
            >
              <component
                :is="item.component || 'a'"
                :key="index"
                v-if="handleRowHandleShow(item.show, scope)"
                :disabled="handleRowHandleDisabled(item.disabled, scope)"
                @click.stop="item.doClick(scope)"
                v-bind="item.componentOptions"
              >
              {{ handleRowHandleText(item.text,scope) }}
              </component>

            </template>

          </template>

        </component>
      </component>
    </div>
    <!-- 底部表格分页 -->
    <div class="d2-crud-footer">
      <div class="d2-crud-pagination" v-if="pagination">
        <el-pagination
          v-bind="pagination"
          @size-change="handlePaginationSizeChange"
          @current-change="handlePaginationCurrentChange"
          @prev-click="handlePaginationPrevClick"
          @next-click="handlePaginationNextClick"
        >
          <slot name="PaginationPrefixSlot" />
        </el-pagination>
      </div>
      <slot name="footer"/>
    </div>
    <!-- 表单对话框 -->
    <component
      :is="'el-drawer'"
      class="form-drawer"
      :visible="isDialogShow"
      v-on:update:visible="handleDialogShowUpdate"
      :before-close="handleDialogCancel"
      v-bind="formOptions.wrapperOptions"
    >
      <template slot="title">
        <slot name="formHeaderSlot" v-if="$slots.formHeaderSlot" :mode="formMode" :data="formData"  :form="formData" :row="formDataStorage"/>
        <span v-else> {{getFormTitle()}}</span>
      </template>
      <div class="form-wrapper">
      <div class="form-body">
        <el-scrollbar style="height:100%">
          <el-form
          v-if="isFormShow"
          ref="form"
          :model="formData"
          :rules="handleFormRulesMode()"
          :fullscreen="formOptions.fullscreen"
          :formMode="formMode"
          @submit.native.prevent
          v-bind="formOptions"
        >
          <el-row v-bind="formOptions" >
            <template v-for="(item,key, index) in formTemplateStorage" >
              <el-col :key="index"
                v-if="getTemplateComponentAttr(item,'show', true,getContext(key))"
                :span="getTemplateComponentAttr(item,'span', 24)"
                :offset="getTemplateComponentAttr(item,'offset', 0)"
              >
                <d2-form-item
                  :template="item"
                  :colKey="key"
                  :formData="formData"
                  :ref="'form_item_'+key"
                  :formMode="formMode"
                  :getColumn="getFormComponentRef"
                  @form-data-change="handleFormDataChange"
                  @form-component-ready="handleFormComponentReady"
                  @form-component-custom-event="handleFormComponentCustomEvent"
                >
                  <template :slot="key+'FormSlot'">
                      <slot :name="key+'FormSlot'" :form="formData" :mode="formMode" :row="formDataStorage"/>
                  </template>
                  <template :slot="key+'HelperSlot'">
                      <slot :name="key+'HelperSlot'" :form="formData" :mode="formMode" :row="formDataStorage"/>
                  </template>
                </d2-form-item>
              </el-col>
            </template>
          </el-row>
        </el-form>
      </el-scrollbar>
      </div>
      <div class="form-footer">
        <slot name="formFooterSlot" :mode="formMode" :data="formData" :form="formData" :row="formDataStorage">
          <el-row :gutter="12">
            <el-col :span="12">
              <el-button v-if="getAttribute(formOptions,'cancelButtonShow', true)"
                :size="getAttribute(formOptions,'cancelButtonSize', null)"
                :type="getAttribute(formOptions,'cancelButtonType' ,null)"
                :icon="getAttribute(formOptions,'cancelButtonIcon', null)"
                :disabld="getAttribute(formOptions,'cancelButtonDisabled', false)"
                style="width:100%"
                @click="isDialogShow = false"
              >
              {{ getAttribute(formOptions,'cancelButtonText', '取消')}}
              </el-button>

            </el-col>
            <el-col :span="12">
                  <el-button v-if="getAttribute(formOptions,'saveButtonShow', true)"
                :size="getAttribute(formOptions,'saveButtonSize', null)"
                :type="getAttribute(formOptions,'saveButtonType' ,'primary')"
                :icon="getAttribute(formOptions,'saveButtonIcon', null)"
                :disabld="getAttribute(formOptions,'saveButtonDisabled', false)"
                :loading="getAttribute(formOptions,'saveLoading', false)"
                style="width:100%"
                @click="handleDialogSave"
              >
              {{ getAttribute(formOptions,'saveButtonText', '确定')}}
              </el-button>
            </el-col>
          </el-row>
        </slot>

      </div>
      </div>
    </component>
  </div>
</template>

<script>
import base from './mixin/base'
import handleRow from './mixin/handleRow'
import data from './mixin/data'
import edit from './mixin/edit'
import add from './mixin/add'
import remove from './mixin/remove'
import dialog from './mixin/dialog'
import pagination from './mixin/pagination'
import exposeMethods from './mixin/exposeMethods.js'
import utils from './mixin/utils'
import D2Column from './components/d2-column'
import D2FormItem from './components/d2-form-item'
import view from './mixin/view'

export default {
  name: 'd2-crud',
  provide: function () {
    return {
      d2CrudContext: {
        d2Crud: this,
        getForm: this.getFormData,
        getLineEditor: () => { // 行编辑模式
          return this.lineEditor
        }
      }
    }
  },
  data () {
    return {
      cells: {

      }
    }
  },
  mixins: [
    base,
    data,
    handleRow,
    edit,
    add,
    view,
    remove,
    dialog,
    pagination,
    exposeMethods,
    utils
  ],
  components: {
    D2FormItem,
    D2Column
  },
  computed: {
    _tableListeners () {
      const events = this.options.events || {}
      return {
        'select-all': this.handleSelectAll,
        'selection-change': this.handleSelectionChange,
        ...events
      }
    }
  },
  methods: {
    handleFormDataChange (event) {
      this.$emit('form-data-change', { getComponent: this.getFormComponentRef, ...event })
    },
    handleFormComponentReady (event) {
      this.$emit('form-component-ready', event)
    },
    handleFormComponentCustomEvent (event) {
      this.$emit('form-component-custom-event', event)
    },
    handleCellDataChange (column) {
      this.$emit('cell-data-change', column)
    },
    handleCellComponentReady (column) {
      this.$emit('cell-component-ready', column)
    },
    handleCellComponentCustomEvent (column) {
      this.$emit('cell-component-custom-event', column)
    },
    getFormComponentRef (key) {
      if (this.$refs) {
        const wrapper = this.$refs['form_item_' + key]
        if (wrapper && wrapper.length > 0 && wrapper[0]) {
          return wrapper[0].getComponentRef()
        }
      }
    },
    buildColumnKey (item, index) {
      return (item.key || '') + (item.uniqueKey || '') + index // + new Date().getTime()
    },
    getContext (key) {
      return {
        mode: this.formMode,
        key: key,
        value: this.formData[key],
        form: this.formData,
        row: this.formDataStorage
      }
    }
  }
}
</script>

<style lang="scss">

// 表格
.d2-crud-x {
  display: flex;
  flex-direction: column;
  .d2-crud-header {
    border-bottom: solid 1px  #DCDFE6;
    padding-bottom: 10px;
  }
  .d2-crud-body {
    padding: 15px 0;
    overflow: hidden;
    flex-grow: 1;
  }
  .d2-crud-pagination {
    padding: 15px 0;
  }
  .form-item-helper{
    display: block;
    margin-top: 5px;
    margin-bottom: 5px;
    color: #737373;
    line-height: normal;
  }
  .el-collapse-item__wrap {
    padding-top: 7px;
  }
  .el-collapse-item__content{
    padding-left:30px;
    padding-right:30px;
  }
  &.d2-crud-height100{
    height:100%;
    .d2-crud-body{
      height: 100%;
    }
  }
  // 分页
  .d2-crud-pagination{
    .el-pagination{
      .prefix{
        button{
          background-color: #f4f4f5;
          min-width: 30px;
        }
      }
    }
  }
}
// 表单
.form-drawer{
  .form-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .form-body {
    position: relative;
    flex: 1;
    overflow: auto;
    padding: 20px 10px 20px 20px;
    .el-scrollbar__wrap {
      overflow-x: hidden;
    }
    .el-form {
      padding-right: 10px;
    }
  }
  .form-footer{
    position: relative;
    padding: 20px;
    box-shadow: 0px -2px 12px 0px rgba(74,88,146,0.1);
  }
  .el-drawer__header {
    margin-bottom: 0px;
    padding: 20px 20px 20px;
    border-bottom: solid 1px #eee;
  }
}
</style>
