import store from '@/store'
import { checkAuthByMenuKey, redirectFirstChildRoute } from '@/utils/menu'
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: 'login' */'@/views/login/index.vue')
  },
  {
    path: '/analyse',
    name: 'analyse',
    meta: { title: '综合分析中心', menuAuth: 'comprehensive_analysis', requiresAuth: true },
    redirect: redirectFirstChildRoute(),
    component: () => import(/* webpackChunkName: 'layout' */ '@/layout/index.vue'),
    children: [
      {
        path: 'older-add',
        name: 'olderAdd',
        component: () => import(/* webpackChunkName: 'olderAdd' */ '@/views/older-manage/add/index.vue'),
        meta: { title: '新增老人', menuAuth: 'ca_older_manage_add' }
      },
      {
        path: 'older-batch-add',
        name: 'olderBatchAdd',
        component: () => import(/* webpackChunkName: 'olderBatchAdd' */ '@/views/older-manage/batch-add/index.vue'),
        meta: { title: '批量导入老年人信息', menuAuth: 'ca_older_manage_batch_add' }
      },
      {
        path: 'older-list',
        name: 'olderList',
        component: () => import(/* webpackChunkName: 'olderList' */ '@/views/older-manage/list/index.vue'),
        meta: { title: '老人列表', menuAuth: 'ca_older_manage_list' }
      },
      {
        path: 'older-check-list',
        name: 'olderCheckList',
        component: () => import(/* webpackChunkName: 'olderCheckList' */ '@/views/older-manage/check-list/index.vue'),
        meta: { title: '老人档案审核', menuAuth: 'ca_older_check_list' }
      },
      {
        path: 'health-portrait',
        name: 'healthPortrait',
        component: () => import(/* webpackChunkName: 'healthPortrait' */ '@/views/older-portrait/health-portrait/index.vue'),
        meta: { title: '健康画像', menuAuth: 'ca_older_health_portrait' }
      },
      {
        path: 'house-portrait',
        name: 'housePortrait',
        component: () => import(/* webpackChunkName: 'housePortrait' */ '@/views/older-portrait/house-portrait/index.vue'),
        meta: { title: '居住屋画像', menuAuth: 'ca_older_home_portrait' }
      },
      {
        path: 'elect-fence',
        name: 'electFence',
        component: () => import(/* webpackChunkName: 'electFence' */ '@/views/older-portrait/elect-fence/index.vue'),
        meta: { title: '电子围栏', menuAuth: 'ca_older_electric_fence' }
      },
      {
        path: 'device-supplier',
        name: 'deviceSupplier',
        component: () => import(/* webpackChunkName: 'deviceSupplier' */ '@/views/device-manage/device-supplier/index.vue'),
        meta: { title: '供应商管理', menuAuth: 'ca_device_supplier' }
      },
      {
        path: 'device-model',
        name: 'deviceModel',
        component: () => import(/* webpackChunkName: 'deviceModel' */ '@/views/device-manage/device-model/index.vue'),
        meta: { title: '设备型号维护', menuAuth: 'ca_device_type' }
      },
      {
        path: 'device-add',
        name: 'deviceAdd',
        component: () => import(/* webpackChunkName: 'deviceAdd' */ '@/views/device-manage/device-add/index.vue'),
        meta: { title: '新增设备', menuAuth: 'ca_device_add' }
      },
      {
        path: 'device-batch-add',
        name: 'deviceBatchAdd',
        component: () => import(/* webpackChunkName: 'deviceBatchAdd' */ '@/views/device-manage/device-batch-add/index.vue'),
        meta: { title: '电子围栏', menuAuth: 'ca_device_batch_add' }
      },
      {
        path: 'device-list',
        name: 'deviceList',
        component: () => import(/* webpackChunkName: 'deviceList' */ '@/views/device-manage/device-list/index.vue'),
        meta: { title: '设备列表', menuAuth: 'ca_device_list' }
      }
    ]
  },
  {
    path: '/safe',
    name: 'safe',
    meta: { title: '居家安全中心', menuAuth: 'home_safety', requiresAuth: true },
    component: () => import(/* webpackChunkName: 'layout' */ '@/layout/index.vue')
  },
  {
    path: '/service',
    name: 'service',
    meta: { title: '居家服务中心', menuAuth: 'home_care', requiresAuth: true },
    redirect: redirectFirstChildRoute(),
    component: () => import(/* webpackChunkName: 'layout' */ '@/layout/index.vue'),
    children: [
      {
        path: 'organization',
        name: 'organization',
        component: () => import(/* webpackChunkName: 'organization' */ '@/views/service-manage/organization/index.vue'),
        meta: { title: '机构管理', menuAuth: 'hc_service_organization' }
      },
      {
        path: 'project',
        name: 'project',
        component: () => import(/* webpackChunkName: 'project' */ '@/views/service-manage/project/index.vue'),
        meta: { title: '服务项目管理', menuAuth: 'hc_service_project' }
      },
      {
        path: 'attendant',
        name: 'attendant',
        component: () => import(/* webpackChunkName: 'attendant' */ '@/views/service-manage/attendant/index.vue'),
        meta: { title: '服务人员管理', menuAuth: 'hc_service_person' }
      },
      {
        path: 'gov-project',
        name: 'govProject',
        component: () => import(/* webpackChunkName: 'govProject' */ '@/views/service-manage/gov-project/index.vue'),
        meta: { title: '服务项目管理（政府）', menuAuth: 'hc_service_project_government' }
      },
      {
        path: 'care',
        name: 'care',
        component: () => import(/* webpackChunkName: 'care' */ '@/views/care-managge/index.vue'),
        meta: { title: '关怀项目&提醒设置', menuAuth: 'hc_care_project_set' }
      },
      {
        path: 'notify',
        name: 'notify',
        component: () => import(/* webpackChunkName: 'notify' */ '@/views/learn-activity/notify/index.vue'),
        meta: { title: '通知公告', menuAuth: 'hc_learn_notify' }
      },
      {
        path: 'activity',
        name: 'activity',
        component: () => import(/* webpackChunkName: 'activity' */ '@/views/learn-activity/activity/index.vue'),
        meta: { title: '活动报名', menuAuth: 'hc_learn_activity' }
      },
      {
        path: 'order-create',
        name: 'orderCreate',
        component: () => import(/* webpackChunkName: 'orderCreate' */ '@/views/service-order/create/index.vue'),
        meta: { title: '服务工单创建', menuAuth: 'hc_order_create' }
      },
      {
        path: 'order-list',
        name: 'orderList',
        component: () => import(/* webpackChunkName: 'orderList' */ '@/views/service-order/list/index.vue'),
        meta: { title: '服务工单列表', menuAuth: 'hc_order_list' }
      },
      {
        path: 'order-ledger',
        name: 'orderLedger',
        component: () => import(/* webpackChunkName: 'orderLedger' */ '@/views/service-order/ledger/index.vue'),
        meta: { title: '服务台账', menuAuth: 'hc_order_ledger' }
      }
    ]
  },
  {
    path: '/setting',
    name: 'setting',
    meta: { title: '设置', menuAuth: 'saas_manage', requiresAuth: true },
    component: () => import(/* webpackChunkName: 'layout' */ '@/layout/index.vue'),
    redirect: redirectFirstChildRoute(),
    children: [
      {
        path: 'role',
        name: 'role',
        component: () => import(/* webpackChunkName: 'role' */ '@/views/user-manage/role/index.vue'),
        meta: { title: '角色管理', menuAuth: 'ca_older_manage_user_role' }
      },
      {
        path: 'user',
        name: 'user',
        component: () => import(/* webpackChunkName: 'user' */ '@/views/user-manage/user/index.vue'),
        meta: { title: '用户管理', menuAuth: 'ca_older_manage_user_user' }
      },
      {
        path: 'log',
        name: 'log',
        component: () => import(/* webpackChunkName: 'daily' */ '@/views/log-manage/index.vue'),
        meta: { title: '日志管理', menuAuth: 'saas_manage_log' }
      }
    ]
  },
  {
    path: '/',
    meta: { title: '首页', requiresAuth: true },
    component: () => import(/* webpackChunkName: 'layout' */ '@/layout/index.vue'),
    children: [
      {
        path: '/',
        name: 'welcome',
        meta: { title: '欢迎', requiresAuth: true },
        component: () => import(/* webpackChunkName: 'welcome' */ '@/views/welcome/index.vue')
      },
      {
        path: '/304',
        name: '304',
        component: () => import(/* webpackChunkName: 'error' */'@/views/error/index.vue')
      },
      {
        path: '*',
        name: '404',
        redirect: '/'
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach(async (to, from, next) => {
  // 匹配需要校验就校验，也会匹配到父路由
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.state.token) {
      next({
        name: 'login',
        query: {
          redirect: to.fullPath
        }
      })
    } else {
      if (!store.state.user) {
        await store.dispatch('getUserInfo')
      }
      // 校验当前路由权限， 无权限跳转304页面
      if ((to.meta as any).menuAuth && !checkAuthByMenuKey((to.meta as any).menuAuth)) {
        next({
          name: '304'
        })
      }
      next()
    }
  } else {
    next()
  }
})

export default router
