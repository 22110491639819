<template>
  <el-form
      :inline="true"
      ref="searchForm"
      @submit.native.prevent
      v-bind="$attrs" class="search-form" >
      <slot></slot>
    <el-form-item class="search-btns">
      <el-button  @click="handleFormReset">重置</el-button>
      <el-button @click="handleFormSubmit">查询</el-button>
    </el-form-item>
    <slot name="action"></slot>
  </el-form>
</template>

<script>
import _debounce from 'lodash.debounce'
export default {
  name: 'YSearch',
  inject: ['yTablePro'],
  props: {
    options: {
      type: Object,
      default () {
        return {
          searchAfterReset: true
        }
      }
    }
  },
  created () {
    // 构建防抖查询函数
    if (this.options.debounce !== false) {
      let wait = null
      if (this.options.debounce) {
        wait = this.options.debounce.wait
      }
      if (wait == null) {
        wait = 500
      }
      // 查询防抖
      this.searchDebounce = _debounce(this.handleFormSubmit, wait, this.options.debounce)
    }
  },
  methods: {
    handleFormSubmit () {
      if (this.searchDebounce) {
        // 防抖查询取消
        this.searchDebounce.cancel()
      }
      this.$refs.searchForm.validate((valid) => {
        if (valid) {
          this.yTablePro?.reset()
          this.$emit('submit')
        } else {
          return false
        }
      })
    },
    handleFormReset () {
      this.$refs.searchForm.resetFields()
      // 表单重置事件
      this.$emit('reset')
      if (this.options.searchAfterReset) {
        this.$nextTick(() => {
          this.handleFormSubmit()
        })
      }
    }
  }
}
</script>
<style lang="scss">
  .search-form{
    .el-form-item {
      margin-bottom: 8px;
    }
  }
</style>
