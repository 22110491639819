<template>
   <el-drawer
      class="form-drawer"
      :visible.sync="visible"
      @closed="handleClosed"
      v-bind="$attrs"
      v-on="$listeners"
      size="550px"
      :title="title"
    >
      <template slot="title">
        <slot name="title" v-if="$slots.title" :form="model" mode="mode" />
      </template>
      <div class="form-wrapper">
      <div class="form-body">
        <el-scrollbar style="height:100%">
        <el-form
          ref="form"
          @submit.native.prevent
          label-width="80px"
          label-position="right"
          v-bind="formOptions"
          :model="model"
        >
          <slot />
        </el-form>
      </el-scrollbar>
      </div>
      <div class="form-footer" v-if="!$slots.footer">
        <slot name="footer" :form="model">
          <el-row :gutter="12">
            <el-col :span="12">
              <el-button v-if="formOptions.cancelButtonProps"
                v-bind="formOptions.cancelButtonProps"
                style="width:100%"
                @click="handleCancel"
              >
              {{ formOptions.cancelButtonProps.text || '取消' }}
              </el-button>

            </el-col>
            <el-col :span="12">
              <el-button v-if="formOptions.okButtonProps"
                type="primary"
                v-bind="formOptions.okButtonProps"
                :loading="saveLoading"
                style="width:100%"
                @click="handleConfirm"
              >
              {{ formOptions.okButtonProps.text || '确定' }}
              </el-button>
            </el-col>
          </el-row>
        </slot>
      </div>
      </div>
    </el-drawer>
</template>

<script>

export default {
  name: 'YDrawer',
  inject: ['yTablePro'],
  props: {
    formOptions: {
      type: Object,
      default () {
        return {
          cancelButtonProps: {},
          okButtonProps: {}
        }
      }
    },
    model: {
      type: Object,
      default () {
        return {}
      }
    },
    onOk: {
      type: Function
    }
  },
  data () {
    return {
      mode: 'add',
      visible: false,
      saveLoading: false
    }
  },
  computed: {
    title () {
      if (this.mode === 'add') {
        return '新增'
      } else if (this.mode === 'edit') {
        return '编辑'
      } else if (this.mode === 'view') {
        return '查看'
      }
      return '新增'
    }
  },
  methods: {
    open (mode = 'add') {
      this.mode = mode
      this.visible = true
    },
    handleCancel () {
      this.visible = false
    },
    async handleConfirm () {
      const valid = await this.$refs.form.validate()
      if (valid) {
        this.saveLoading = true
        await this.onOk?.(this.model).finally(() => {
          this.saveLoading = false
        })
        this.yTablePro.reset()
        this.$message('保存成功')
        this.visible = false
      }
    },
    handleClosed () {
      this.$refs.form.resetFields()
      this.$emit('closed')
    }
  }
}
</script>

<style lang="scss" scoped>
.form-drawer{
  .form-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .form-body {
    position: relative;
    flex: 1;
    overflow: auto;
    padding: 20px 10px 20px 20px;
    .el-scrollbar__wrap {
      overflow-x: hidden;
    }
    .el-form {
      padding-right: 10px;
    }
  }
  .form-footer{
    position: relative;
    padding: 20px;
    box-shadow: 0px -2px 12px 0px rgba(74,88,146,0.1);
  }
  .el-drawer__header {
    margin-bottom: 0px;
    padding: 20px 20px 20px;
    border-bottom: solid 1px #eee;
  }
}
</style>
