import Vue from 'vue'
import PageContainer from './page-container/index.vue'
import TablePro from './table-pro/index.vue'
import Search from './table-pro/search/index.vue'
import Drawer from './table-pro/drawer/index.vue'

const components = [PageContainer, TablePro, Search, Drawer]

export default {
  install () {
    components.forEach(component => {
      Vue.component(component.name, component)
    })
  }
}
