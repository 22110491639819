import { IPageList } from '@/types/common'
import { IPermissionTreeNode, IUserInfoRes, IUserItem } from '@/types/user'
import request from '@/utils/request'

interface User {
  username: string;
  password: string;
  captcha_key?: string;
  captcha_value?: string;
}

// 登录
export function login (data: User) {
  return request<{
    token: string;
  }>({
    method: 'POST',
    url: '/api/saas/admin/login',
    data: data
  })
}

// 获取用户信息
export function getLoginInfo () {
  return request<IUserInfoRes>({
    method: 'GET',
    url: '/api/saas/admin/get-admin-info'
  })
}

// 退出
export function logout () {
  return request({
    method: 'POST',
    url: '/api/saas/admin/logout'
  })
}

// 修改密码
interface ChangePasswordData {
  curr_pwd: string;
  update_pwd: string;
  re_update_pwd: string;
}
export function changePassword (data: ChangePasswordData) {
  return request({
    method: 'POST',
    url: '/api/saas/admin/change-password',
    data: data
  })
}

// 获取用户列表
export function getUserList (params) {
  return request<IPageList<IUserItem>>({
    method: 'GET',
    url: '/api/saas/admin/list',
    params
  })
}

export function getUserInfo (id: number) {
  return request<IUserItem>({
    method: 'GET',
    url: '/api/saas/admin/info',
    params: { id }
  })
}

// 保存用户
export function saveUser (data: any) {
  return request({
    method: 'POST',
    url: '/api/saas/admin/add',
    data
  })
}

// 修改用户
export function updateUser (data: any) {
  return request({
    method: 'POST',
    url: '/api/saas/admin/edit',
    data
  })
}

// 删除用户
export function deleteUser (id) {
  return request({
    method: 'POST',
    url: '/api/saas/admin/del',
    data: { id }
  })
}

// 获取权限树
export function getPermissionTree () {
  return request<IPermissionTreeNode[]>({
    method: 'GET',
    url: '/api/saas/admin/permission/tree'
  })
}
