import { IMenuItem } from '@/types/user'
import store from '@/store'

// 服务器菜单与本地菜单信息映射
export const MenusMap = {
  comprehensive_analysis: {
    name: '综合分析中心',
    img: 'comprehensive_analysis',
    path: '/analyse'
  },
  ca_older_manage: {
    name: '用户画像',
    img: 'ca_older_manage'
  },
  ca_older_manage_add: {
    name: '新增老人',
    path: '/analyse/older-add'
  },
  ca_older_manage_batch_add: {
    name: '批量导入老人',
    path: '/analyse/older-batch-add'
  },
  ca_older_manage_list: {
    name: '老人列表',
    path: '/analyse/older-list'
  },
  ca_older_check_list: {
    name: '老人档案审核',
    path: '/analyse/older-check-list'
  },
  ca_older_info: {
    name: '用户画像',
    img: 'ca_older_info'
  },
  ca_older_health_portrait: {
    name: '健康画像',
    path: '/analyse/health-portrait'
  },
  ca_older_home_portrait: {
    name: '居住屋画像',
    path: '/analyse/house-portrait'
  },
  ca_older_electric_fence: {
    name: '电子围栏',
    path: '/analyse/elect-fence'
  },
  ca_device: {
    name: '设备管理',
    img: 'ca_device'
  },
  ca_device_supplier: {
    name: '供应商维护',
    path: '/analyse/device-supplier'
  },
  ca_device_type: {
    name: '设备型号维护',
    path: '/analyse/device-model'
  },
  ca_device_add: {
    name: '新增设备',
    path: '/analyse/device-add'
  },
  ca_device_batch_add: {
    name: '批量新增设备',
    path: '/analyse/device-batch-add'
  },
  ca_device_list: {
    name: '设备列表',
    path: '/analyse/device-list'
  },
  ca_screen: {
    name: '综合分析中心大屏',
    img: 'ca_screen',
    path: 'https://www.iconfont.cn/'
  },
  home_safety: {
    name: '居家安全中心',
    img: 'home_safety',
    path: 'https://www.baidu.com/'
  },
  // 居家服务模块
  home_care: {
    name: '居家服务中心',
    img: 'home_care',
    path: '/service'
  },
  hc_service: {
    name: '服务商管理',
    img: 'ca_older_info'
  },
  hc_service_organization: {
    name: '机构管理',
    path: '/service/organization'
  },
  hc_service_project: {
    name: '服务项目管理',
    path: '/service/project'
  },
  hc_service_person: {
    name: '服务人员管理',
    path: '/service/attendant'
  },
  hc_service_project_government: {
    name: '服务项目管理（政府）',
    path: '/service/gov-project'
  },
  hc_care: {
    name: '关怀提醒',
    img: 'ca_older_info'
  },
  hc_care_project_set: {
    name: '关怀项目&提醒设置',
    path: '/service/care'
  },
  hc_learn: {
    name: '学娱无忧',
    img: 'ca_older_info'
  },
  hc_learn_notify: {
    name: '通知公告',
    path: '/service/notify'
  },
  hc_learn_activity: {
    name: '活动报名',
    path: '/service/activity'
  },
  hc_order: {
    name: '服务工单',
    img: 'ca_older_info'
  },
  hc_order_create: {
    name: '服务工单创建',
    path: '/service/order-create'
  },
  hc_order_list: {
    name: '服务工单列表',
    path: '/service/order-list'
  },
  hc_order_ledger: {
    name: '服务台账',
    path: '/service/order-ledger'
  },
  hc_screen: {
    name: '居家服务中心大屏',
    img: 'ca_screen',
    path: 'https://tailwindcss.com/'
  },
  // 系统管理模块
  saas_manage: {
    name: '系统管理',
    img: 'saas_manage',
    path: '/setting'
  },
  ca_older_manage_user_role: {
    name: '角色管理',
    path: '/setting/role'
  },
  saas_manage_user: {
    name: '用户管理',
    img: 'saas_manage_user'
  },
  ca_older_manage_user_user: {
    name: '用户管理',
    path: '/setting/user'
  },
  saas_manage_log: {
    name: '日志管理',
    path: '/setting/log',
    img: 'saas_manage_log'
  }
}
// 查找某个菜单节点
export const findMenuNodeByKey = (menuKey: string) => {
  const menu = store.state.menu
  let currentNode = null
  const recurrence = (list: any[], key: string) => {
    for (const item of list) {
      if (item.guard_name === menuKey) {
        currentNode = item
        return
      } else if (Array.isArray(item.sub_menu)) {
        recurrence(item.sub_menu || [], key)
      }
    }
  }
  recurrence(menu, menuKey)
  return currentNode
}

// 查找菜单列表的第一个路由菜单
export const findFirstRouteMenuNode = (menu: any[]) => {
  const recurrence = (list: any[]) => {
    for (const item of list) {
      if (item.sub_menu?.length && item.sub_menu?.find(sub => sub.is_nav)) {
        const currentNode = recurrence(item.sub_menu)
        if (currentNode) {
          return currentNode
        }
      } else {
        return item
      }
    }
  }

  return recurrence(menu)
}
// 生成格式化菜单
export const genMenu = (menuList: IMenuItem[]) => {
  return menuList.map(item => {
    if (item.sub_menu && item.sub_menu.length) {
      return { ...(MenusMap[item.guard_name] || {}), ...item, sub_menu: genMenu(item.sub_menu) }
    }
    return { ...(MenusMap[item.guard_name] || {}), ...item }
  })
}

// 生成当前一级路径的侧边栏菜单
export const genAuthAsideMenu = (menuList: IMenuItem[], firstPath: string) => {
  const currentMenu = menuList.find(item => item.path === firstPath)
  return currentMenu?.sub_menu || []
}

// 重定向父路由的第一个有权限子菜单路由
export const redirectFirstChildRoute = () => {
  return (to) => {
    const { sub_menu } = findMenuNodeByKey(to.meta.menuAuth) || { sub_menu: [] }
    const firstChildRoute = findFirstRouteMenuNode(sub_menu)
    return { path: firstChildRoute.path }
  }
}

// 校验权限
export const checkAuthByMenuKey = (menuKey: string) => {
  const currentNode = findMenuNodeByKey(menuKey)
  return !!currentNode
}

// 获取选中的节点列表
export const getSelectNodeIdList = (menuList: IMenuItem[]) => {
  const selectList: number[] = []
  const recurrence = (list: IMenuItem[]) => {
    for (const item of list) {
      if (item.is_select === 1) {
        selectList.push(item.id)
      } else {
        recurrence(item.sub_menu || [])
      }
    }
  }
  recurrence(menuList)
  return selectList
}

// 按钮权限指令， 根据guard_name
