import axios, { AxiosRequestConfig } from 'axios'
import store from '@/store'
import { Message } from 'element-ui'
import router from '@/router'

export interface ApiResponse<T> {
  code: number;
  msg: string;
  data: T;
}

function redirectLogin () {
  router.push({
    name: 'login',
    query: {
      redirect: router.currentRoute.fullPath
    }
  })
  window.localStorage.removeItem('token')
}

const request = axios.create({})

request.interceptors.request.use(function (config) {
  const token = store.state.token
  if (token) {
    config.headers.Authorization = token
  }
  return config
}, function (error) {
  return Promise.reject(error)
})

request.interceptors.response.use(function (response) { // 请求响应成功
  // 自定义错误状态码，错误处理这里
  if (response.data.code === 200) {
    return response
  } else if (response.data.code === 10001) { // 参数错误
    Message.error('登录过期，重新登录')
    redirectLogin()
  } else {
    Message.error(response.data.msg || '错误')
  }
  // eslint-disable-next-line prefer-promise-reject-errors
  return Promise.reject(response.data)
}, async function (error) {
  if (error.response) {
    const { status } = error.response
    if (status === 400) {
      Message.error('请求参数错误')
    } else if (status === 401) { // token问题
      redirectLogin()
    } else if (status === 403) {
      Message.error('没有权限，请联系管理员')
    } else if (status === 404) {
      Message.error('请求资源不存在')
    } else if (status >= 500) {
      Message.error('服务端错误，请联系管理员')
    }
  } else if (error.request) {
    Message.error('请求超时，请刷新重试')
  } else {
    Message.error(`请求失败，${error.message}`)
  }
  return Promise.reject(error)
})

export default <T>(params: AxiosRequestConfig) => {
  return request(params).then(res => {
    return res.data as ApiResponse<T>
  })
}
