<template>
  <div class="page-container">
    <div v-if="$slots.header" class="page-container__header" ref="header">
      <slot name="header"/>
    </div>
    <div class="page-container__body" ref="body">
      <slot/>
    </div>
    <div v-if="$slots.footer" class="page-container__footer" ref="footer">
      <slot name="footer"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'YPageContainer'
}
</script>

<style lang="scss">
.page-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 20px;
  top: 20px;
  right: 20px;
  bottom: 20px;
  background: #FFFFFF;
  box-shadow: 2px 0px 12px 0px rgba(74,88,146,0.1);
  border-radius: 12px;
  &__header, &__footer {
    position: relative;
    padding: 0 20px;
  }
  &__header {
    padding: 20px 0;
    margin: 0 20px;
    border-bottom: solid 1px  #DCDFE6;
  }
  &__body {
    position: relative;
    padding: 20px;
    flex: 1;
    overflow: auto;
  }
}
</style>
